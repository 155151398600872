.speaking-border-for-initials > .ms-Persona-coin > .ms-Persona-imageArea > .ms-Persona-initials {
  box-shadow: 0px 0px 0px 3px #ca5010,  0px 0px 20px 5px #ca5010;
}

.speaking-border-for-video {
  box-shadow: 0px 0px 0px 3px #ca5010;
}
.icon-text-large {
  vertical-align: middle;
  font-size: large;
}

.icon-text-xlarge {
  vertical-align: middle;
  font-size: x-large;
}

